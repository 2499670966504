
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.wrapper {
    padding: 50px 0;
    background-color: $bestparaquatlawyers-green-dark;
    @apply mx-auto flex flex-col-reverse md:flex-row justify-between items-center;
    color: white;

    h2 {
        @apply text-lg mb-4;
        font-style: normal;
        font-weight: 700;
        line-height: 38px;
        letter-spacing: 0em;
        text-align: left;
        @include md {
            @apply text-2xl;
        }
    }
}
